import React from 'react';
import AllCompanies from '../components/AllCompanies';
import CompanyList from '../components/CompanyList';
import ProductList from '../components/ProductList';

const Companies = () => {
  return (
    <div className="main_content">
      <div className="mcontainer">
        <div className="flex justify-between items-center relative md:mb-4 mb-3">
          <div className="flex-1">
            <h2 className="text-2xl font-semibold"> Companies </h2>
            {/* <nav className="responsive-nav border-b md:m-0 -mx-4">
                            <ul>
                                <li className="active"><a href="#" className="lg:px-2">   Suggestions </a></li>
                                <li><a href="#" className="lg:px-2"> Newest </a></li>
                                <li><a href="#" className="lg:px-2"> My products </a></li>
                            </ul>
                        </nav> */}
          </div>
        </div>

        <CompanyList />

        <AllCompanies />
      </div>
    </div>
  );
};

export default Companies;
