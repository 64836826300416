import React from 'react';

const ProfileCover = () => {
  return (
    <div className="profile user-profile">
      <div className="profiles_banner">
        <img src="/assets/images/avatars/profile-cover.jpg" alt="" />
        <div className="profile_action absolute bottom-0 right-0 space-x-1.5 p-3 text-sm z-50 hidden lg:flex">
          {/* <a
            href="#"
            className="flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white space-x-1.5"
          >
            <ion-icon name="crop-outline" className="text-xl"></ion-icon>
            <span> Crop </span>
          </a> */}
          <a
            href="#"
            className="flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white space-x-1.5"
          >
            <ion-icon name="create-outline" className="text-xl"></ion-icon>
            <span> Edit </span>
          </a>
        </div>
      </div>
      <div className="profiles_content">
        <div className="profile_avatar">
          <div className="profile_avatar_holder">
            <img src="/assets/images/avatars/avatar-8.jpg" alt="" />
          </div>
          <div className="user_status status_online"></div>
          <div className="icon_change_photo" hidden>
            {' '}
            <ion-icon name="camera" className="text-xl"></ion-icon>{' '}
          </div>
        </div>

        <div className="profile_info">
          <h1> Josephine Williams </h1>
          <p>
            {' '}
            Family , Food , Fashion , Fourever <a href="#">Edit </a>
          </p>
        </div>
      </div>

      <div className="flex justify-between lg:border-t border-gray-100 flex-col-reverse lg:flex-row pt-2">
        <nav className="responsive-nav pl-3">
          <ul uk-switcher="connect: #timeline-tab; animation: uk-animation-fade">
            <li>
              <a href="#">Timeline</a>
            </li>
            <li>
              <a href="#">Services</a>
            </li>
            <li>
              <a href="#">Videos</a>
            </li>
          </ul>
        </nav>

        <div className="flex items-center space-x-1.5 flex-shrink-0 pr-4 mb-2 justify-center order-1 relative">
          <a
            href="#"
            className="flex items-center justify-center h-10 px-5 rounded-md bg-blue-600 text-white space-x-1.5 hover:text-white"
            uk-toggle="target: #create-post-modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span> Create your product </span>
          </a>

          {/* <a
            href="#"
            className="flex items-center justify-center h-10 w-10 rounded-md bg-gray-100"
            uk-toggle="target: #profile-search;animation: uk-animation-slide-top-small"
          >
            <ion-icon name="search" className="text-xl"></ion-icon>
          </a>
          <div
            className="absolute right-3 bg-white z-10 w-full flex items-center border rounded-md"
            id="profile-search"
            hidden
          >
            <input
              type="text"
              onChange={() => {}}
              placeholder="Search.."
              className="flex-1"
            />
            <ion-icon
              name="close-outline"
              className="text-2xl hover:bg-gray-100 p-1 rounded-full mr-2 cursor-pointer"
              uk-toggle="target: #profile-search;animation: uk-animation-slide-top-small"
            ></ion-icon>
          </div> */}

          <a
            href="#"
            className="flex items-center justify-center h-10 w-10 rounded-md bg-gray-100"
          >
            <ion-icon name="ellipsis-horizontal" className="text-xl"></ion-icon>
          </a>
          <div
            className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
            uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small; offset:5"
          >
            <ul className="space-y-1">
              {/* <li>
                <a
                  href="#"
                  className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                >
                  <ion-icon
                    name="arrow-redo-outline"
                    className="pr-2 text-xl"
                  ></ion-icon>{' '}
                  Share Profile
                </a>
              </li> */}
              <li>
                <a
                  href="#"
                  className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                >
                  <ion-icon
                    name="create-outline"
                    className="pr-2 text-xl"
                  ></ion-icon>{' '}
                  Account setting
                </a>
              </li>
              {/* <li>
                <a
                  href="#"
                  className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                >
                  <ion-icon
                    name="notifications-off-outline"
                    className="pr-2 text-lg"
                  ></ion-icon>{' '}
                  Disable notifications
                </a>
              </li> */}
              <li>
                <a
                  href="#"
                  className="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                >
                  <ion-icon
                    name="star-outline"
                    className="pr-2 text-xl"
                  ></ion-icon>{' '}
                  Save Profile
                </a>
              </li>
              {/* <li>
                <hr className="-mx-2 my-2 dark:border-gray-800" />
              </li>
              <li>
                <a
                  href="#"
                  className="flex items-center px-3 py-2 text-red-500 hover:bg-red-50 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                >
                  <ion-icon
                    name="stop-circle-outline"
                    className="pr-2 text-xl"
                  ></ion-icon>{' '}
                  Unfriend
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCover;
