import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import endpoints from '../apis/endpoints';
import { APIContext } from '../utils/Context';
import './Register.css';
const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const { authorized } = useContext(APIContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthority = async () => {
      return await endpoints
        .profile()
        .then(() => {
          window.location.href = '/';
        })
        .catch(async () => {
          setLoading(false);
        });
    };
    checkAuthority();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('username', username);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('passwordConfirmation', passwordConfirmation);
    return await endpoints
      .register(formData)
      .then(async (res) => {
        await localStorage.setItem('token', res.data.payload.token);
        window.location.href = '/feed';
      })
      .catch((err) => {
        setError(
          err.response.data.errors[0].field +
            ' ' +
            err.response.data.errors[0].message
        );
      });
  };
  return (
    <div>
      {!loading && (
        <div className="bg-gray-100">
          <div id="wrapper" className="flex flex-col justify-between h-screen">
            <div className="bg-white py-4 shadow dark:bg-gray-800">
              <div className="max-w-6xl mx-auto">
                <div className="flex items-center lg:justify-between justify-around">
                  <Link to="/feed">
                    <img
                      src="/assets/images/vertigo-logo.png"
                      alt=""
                      className="w-32"
                    />
                  </Link>

                  <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                    <Link to="/login" className="py-3 px-4">
                      Login
                    </Link>
                    <Link
                      to="/register"
                      className="bg-purple-500 purple-500 px-6 py-3 rounded-md shadow text-white"
                    >
                      Register
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
                <form className="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md">
                  <h1 className="lg:text-2xl text-xl font-semibold mb-6">
                    {' '}
                    Register{' '}
                  </h1>

                  {/* <div className="grid lg:grid-cols-2 gap-3">
                    <div>
                        <label className="mb-0"> First Name </label>
                        <input type="text" onChange={() => {}} placeholder="Your Name" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" />
                    </div>
                    <div>
                        <label className="mb-0"> Last  Name </label>
                        <input type="text" onChange={() => {}} placeholder="Last  Name" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" />
                    </div>
                </div> */}
                  <div>
                    <label className="mb-0"> Username </label>
                    <input
                      type="text"
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Username"
                      className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                    />
                  </div>
                  <div>
                    <label className="mb-0"> Email Address </label>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Info@example.com"
                      className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                    />
                  </div>
                  <div>
                    <label className="mb-0"> Password </label>
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="******"
                      className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                    />
                  </div>
                  <div>
                    <label className="mb-0"> Password Confirmation </label>
                    <input
                      type="password"
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                      placeholder="******"
                      className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                    />
                  </div>
                  {error && (
                    <p className="bg-red-600  p-2 mt-5 rounded-md text-center text-white w-full">
                      {error}
                    </p>
                  )}
                  <div>
                    <button
                      type="button"
                      onClick={(e) => handleSubmit(e)}
                      className="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full"
                    >
                      Get Started
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="lg:mb-5 py-3 uk-link-reset">
              <div className="flex flex-col items-center justify-between lg:flex-row max-w-6xl mx-auto lg:space-y-0 space-y-3">
                <div className="flex space-x-2 text-gray-700 uppercase">
                  <a href="#"> About</a>
                  <a href="#"> Help</a>
                  <a href="#"> Terms</a>
                  <a href="#"> Privacy</a>
                </div>
                <p className="capitalize"> © copyright 2020 by Socialite</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
