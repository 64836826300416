import React from 'react';
import OpenChat from '../components/OpenChat';

const Chats = () => {
  return (
    <div className="is_message">
      <div className="main_content">
        <span
          uk-toggle="target: .message-content;"
          className="fixed left-0 top-36 bg-red-600 z-10 py-1 px-4 rounded-r-3xl text-white"
        >
          {' '}
          Users
        </span>

        <div className="messages-container">
          <div className="messages-container-inner">
            <div className="messages-inbox">
              <div className="messages-headline">
                <div className="input-with-icon" hidden>
                  <input
                    id="autocomplete-input"
                    onChange={() => {}}
                    type="text"
                    placeholder="Search"
                  />
                  <i className="icon-material-outline-search"></i>
                </div>
                <h2 className="text-2xl font-semibold">Chats</h2>
                <span className="absolute icon-feather-edit mr-4 text-xl uk-position-center-right cursor-pointer"></span>
              </div>
              <div className="messages-inbox-inner" data-simplebar>
                <ul>
                  <li>
                    <a href="#">
                      <div className="message-avatar">
                        <i className="status-icon status-online"></i>
                        <img src="/assets/images/avatars/avatar-1.jpg" alt="" />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Stella Johnson</h5>
                          <span>4 hours ago</span>
                        </div>
                        <p>
                          laoreet dolore magna aliquam erat volutpat sed diam
                          nonummy
                        </p>
                      </div>
                    </a>
                  </li>

                  <li className="active-message">
                    <a href="#">
                      <div className="message-avatar">
                        <i className="status-icon status-offline"></i>
                        <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Adrian Mohani</h5>
                          <span>Yesterday</span>
                        </div>
                        <p>
                          Sed diam nonummy nibh euismod tincidunt ut laoreet
                          dolore
                        </p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <div className="message-avatar">
                        <i className="status-icon status-offline"></i>
                        <img src="/assets/images/avatars/avatar-3.jpg" alt="" />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Alia Dolgove</h5>
                          <span>2 days ago</span>
                        </div>
                        <p>
                          Dipiscing elit, sed diam nonummy nibh euismod
                          tincidunt{' '}
                        </p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <div className="message-avatar">
                        <i className="status-icon status-online"></i>
                        <img src="/assets/images/avatars/avatar-4.jpg" alt="" />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Jonathan Madano</h5>
                          <span>2 days ago</span>
                        </div>
                        <p>Nisl ut aliquip ex ea commodo consequa!</p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <div className="message-avatar">
                        <i className="status-icon status-online"></i>
                        <img src="/assets/images/avatars/avatar-6.jpg" alt="" />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Stella Johnson</h5>
                          <span>4 hours ago</span>
                        </div>
                        <p>
                          laoreet dolore magna aliquam erat volutpat sed diam
                          nonummy
                        </p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <div className="message-avatar">
                        <i className="status-icon status-offline"></i>
                        <img src="/assets/images/avatars/avatar-5.jpg" alt="" />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Adrian Mohani</h5>
                          <span>Yesterday</span>
                        </div>
                        <p>
                          Hi Tom! Hate to break it to you but I'm actually on
                          vacation
                        </p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <div className="message-avatar">
                        <i className="status-icon status-offline"></i>
                        <img src="/assets/images/avatars/avatar-1.jpg" alt="" />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Alia Dolgove</h5>
                          <span>2 days ago</span>
                        </div>
                        <p>
                          Dipiscing elit, sed diam nonummy nibh euismod
                          tincidunt{' '}
                        </p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <div className="message-avatar">
                        <i className="status-icon status-online"></i>
                        <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Jonathan Madano</h5>
                          <span>2 days ago</span>
                        </div>
                        <p>Nisl ut aliquip ex ea commodo consequa</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="message-content">
              <div className="messages-headline">
                <h4> Stella Johnson </h4>
                <a href="#" className="message-action text-red-500">
                  <i className="icon-feather-trash-2"></i>{' '}
                  <span className="md:inline hidden"> Delete Conversation</span>{' '}
                </a>
              </div>

              <div className="message-content-scrolbar" data-simplebar>
                <div className="message-content-inner">
                  <div className="message-time-sign">
                    <span>28 June, 2020</span>
                  </div>

                  <div className="message-bubble me">
                    <div className="message-bubble-inner">
                      <div className="message-avatar">
                        <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>
                      <div className="message-text">
                        <p>
                          Consectetuer adipiscing elit, sed diam nonummy nibh
                          euismod laoreet.
                        </p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <div className="message-bubble">
                    <div className="message-bubble-inner">
                      <div className="message-avatar">
                        <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>
                      <div className="message-text">
                        <p>
                          Laoreet. dolore magna imperdiet quod mazim placerat
                          facer possim. 👍
                        </p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <div className="message-bubble me">
                    <div className="message-bubble-inner">
                      <div className="message-avatar">
                        <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>
                      <div className="message-text">
                        <p>Albuom commodo consequat. 😉</p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <div className="message-time-sign">
                    <span>Yesterday</span>
                  </div>

                  <div className="message-bubble me">
                    <div className="message-bubble-inner">
                      <div className="message-avatar">
                        <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>
                      <div className="message-text">
                        <p>
                          Option congue nihil imperdiet mazim placerat facer
                          possim.
                        </p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <div className="message-bubble">
                    <div className="message-bubble-inner">
                      <div className="message-avatar">
                        <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>
                      <div className="message-text">
                        <p>
                          {' '}
                          Nam liber tempor cum soluta nobis eleifend doming id
                          quod mazim placerat 😎
                        </p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <div className="message-bubble me">
                    <div className="message-bubble-inner">
                      <div className="message-avatar">
                        <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>
                      <div className="message-text">
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, 🙂
                        </p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <div className="message-bubble">
                    <div className="message-bubble-inner">
                      <div className="message-avatar">
                        <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>
                      <div className="message-text">
                        <div className="typing-indicator">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="message-reply">
                  <textarea
                    cols="1"
                    rows="1"
                    placeholder="Your Message"
                  ></textarea>
                  <button className="button ripple-effect">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chats;
