import React from 'react';
import { Link } from 'react-router-dom';

const CompanyLogo = () => {
  return (
    <li className="my-3">
      <Link to="/company-profile/qodeex">
        <div className="card">
          <div className="card-media h-40">
            <div className="card-media-overly"></div>
            <img
              src="/assets/images/logo.png"
              style={{ objectFit: 'contain' }}
              alt=""
            />
          </div>
          <div className="card-body">
            <div className="ext-lg font-medium mt-1 t truncate text-center">
              {' '}
              Company 1
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default CompanyLogo;
