import React from 'react';
import OpenChat from '../components/OpenChat';

const Upgrade = () => {
  return (
    <div className="is-collapse">
      <div className="main_content">
        <div className="bg-gradient-to-tr flex from-blue-400 h-52 items-center justify-center lg:h-80 pb-10 relative to-blue-300 via-blue-400 w-full">
          <div className="text-center max-w-xl mx-auto z-10 relative px-5">
            <div className="lg:text-4xl text-2xl text-white font-semibold mb-3">
              {' '}
              Pro Packages{' '}
            </div>
            <div className="text-white text-lg font-medium text-opacity-90">
              {' '}
              Choose the plan that suits you best! More features will be
              available thanks to individual plans.{' '}
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto p-7">
          <div className="-mt-16 bg-white p-10 relative rounded-md shadow">
            <div className="text-center text-xl font-semibold">
              {' '}
              Pick your Plan{' '}
            </div>

            <div className="grid md:grid-cols-3 gap-7 mt-12">
              <div className="bg-white border border-gray-100 hover:shadow-md p-6 rounded-xl">
                <div className="font-bold mb-4 text-2xl text-black">
                  {' '}
                  Starter{' '}
                </div>
                <div className="font-medium mb-6 text-gray-400 text-base">
                  {' '}
                  For a small company that wants to show what it's worth.{' '}
                </div>

                <div className="border-b-2 border-blue-50 flex items-baseline mb-10 pb-8 space-x-2">
                  <div className="font-semibold text-4xl text-black">$19</div>
                  <div className="font-medium text-gray-400">/Week</div>
                </div>

                <div className="space-y-4 text- font-medium text-gray-400">
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Featured member </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Verified badge </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Boost up to 5 Posts </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Boost up to 5 Pages </div>
                  </div>
                </div>

                <button className="bg-blue-50 mt-8 py-3 rounded-md text-blue-500 w-full font-semibold">
                  Start 14 Days Free Trial
                </button>
              </div>

              <div className="bg-white border border-gray-100 hover:shadow-md p-6 rounded-xl relative">
                <span className="absolute bg-blue-100 font-semibold px-2.5 py-1 right-4 rounded-full text-blue-600 text-xs">
                  {' '}
                  Popular{' '}
                </span>

                <div className="font-bold mb-4 text-2xl text-black"> Hot </div>
                <div className="font-medium mb-6 text-gray-400 text-base">
                  {' '}
                  For a small company that wants to show what it's worth.{' '}
                </div>

                <div className="border-b-2 border-blue-50 flex items-baseline mb-10 pb-8 space-x-2">
                  <div className="font-semibold text-4xl text-black">$69</div>
                  <div className="font-medium text-gray-400">/Month</div>
                </div>

                <div className="space-y-4 text- font-medium text-gray-400">
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Featured member </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Verified badge </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Boost up to 10 Posts </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Boost up to 10 Pages </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Posts promotion</div>
                  </div>
                </div>

                <button className="mt-8 py-3 rounded-md bg-blue-500 text-white w-full font-semibold">
                  Start 14 Days Free Trial
                </button>
              </div>

              <div className="bg-white border border-gray-100 hover:shadow-md p-6 rounded-xl relative">
                <div className="font-bold mb-4 text-2xl text-black">
                  {' '}
                  Ultimate
                </div>
                <div className="font-medium mb-6 text-gray-400 text-base">
                  {' '}
                  For a small company that wants to show what it's worth.{' '}
                </div>

                <div className="border-b-2 border-blue-50 flex items-baseline mb-10 pb-8 space-x-2">
                  <div className="font-semibold text-4xl text-black">$299</div>
                  <div className="font-medium text-gray-400">/Year</div>
                </div>

                <div className="space-y-4 text- font-medium text-gray-400">
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Featured member </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Verified badge </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Boost up to 20 Posts </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Boost up to 20 Pages </div>
                  </div>
                  <div className="flex items-center space-x-5">
                    <i className="icon-feather-check font-bold text-blue-600"></i>
                    <div> Posts promotion</div>
                  </div>
                </div>

                <button className="bg-blue-50 mt-8 py-3 rounded-md text-blue-500 w-full font-semibold">
                  Start 14 Days Free Trial
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;
