import React from 'react';

const ProfileServices = () => {
  return (
    <div className="card md:p-6 p-2 max-w-3xl mx-auto">
      <h2 className="text-xl font-bold"> Services</h2>
      <nav className="responsive-nav border-b md:m-0 -mx-4">
        <ul>
          <li className="active">
            <a href="#" className="lg:px-2">
              {' '}
              Following{' '}
            </a>
          </li>
          <li>
            <a href="#" className="lg:px-2">
              {' '}
              Newest{' '}
            </a>
          </li>
          <li>
            <a href="#" className="lg:px-2">
              {' '}
              My pages
            </a>
          </li>
          <li>
            <a href="#" className="lg:px-2">
              {' '}
              Suggestions
            </a>
          </li>
        </ul>
      </nav>

      <div className="grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 mt-5">
        <div className="card">
          <a href="timeline-page.html">
            <img
              src="/assets/images/avatars/avatar-4.jpg"
              className="h-36 object-cover rounded-t-md shadow-sm w-full"
            />
          </a>
          <div className="p-3">
            <a
              href="timeline-page.html"
              className="text-base font-semibold mb-0.5"
            >
              {' '}
              John Michael{' '}
            </a>
            <p className="font-medium text-sm">843K Following </p>
            <button className="bg-gray-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
              Following
            </button>
          </div>
        </div>

        <div className="card">
          <a href="timeline-page.html">
            <img
              src="/assets/images/avatars/avatar-3.jpg"
              className="h-36 object-cover rounded-t-md shadow-sm w-full"
            />
          </a>
          <div className="p-3">
            <a
              href="timeline-page.html"
              className="text-base font-semibold mb-0.5"
            >
              Alex Dolgove{' '}
            </a>
            <p className="font-medium text-sm">843K Following </p>
            <button className="bg-gray-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
              Following
            </button>
          </div>
        </div>

        <div className="card">
          <a href="timeline-page.html">
            <img
              src="/assets/images/avatars/avatar-5.jpg"
              className="h-36 object-cover rounded-t-md shadow-sm w-full"
            />
          </a>
          <div className="p-3">
            <a
              href="timeline-page.html"
              className="text-base font-semibold mb-0.5"
            >
              {' '}
              Dennis Han{' '}
            </a>
            <p className="font-medium text-sm">843K Following </p>
            <button className="bg-gray-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
              Following
            </button>
          </div>
        </div>
        <div className="card">
          <a href="timeline-page.html">
            <img
              src="/assets/images/avatars/avatar-7.jpg"
              className="h-36 object-cover rounded-t-md shadow-sm w-full"
            />
          </a>
          <div className="p-3">
            <a
              href="timeline-page.html"
              className="text-base font-semibold mb-0.5"
            >
              {' '}
              Monroe Parker{' '}
            </a>
            <p className="font-medium text-sm">843K Following </p>
            <button className="bg-gray-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
              Following
            </button>
          </div>
        </div>
        <div className="card">
          <a href="timeline-page.html">
            <img
              src="/assets/images/avatars/avatar-6.jpg"
              className="h-36 object-cover rounded-t-md shadow-sm w-full"
            />
          </a>
          <div className="p-3">
            <a
              href="timeline-page.html"
              className="text-base font-semibold mb-0.5"
            >
              {' '}
              Erica Jones{' '}
            </a>
            <p className="font-medium text-sm">843K Following </p>
            <button className="bg-gray-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
              Following
            </button>
          </div>
        </div>
        <div className="card">
          <a href="timeline-page.html">
            <img
              src="/assets/images/avatars/avatar-2.jpg"
              className="h-36 object-cover rounded-t-md shadow-sm w-full"
            />
          </a>
          <div className="p-3">
            <a
              href="timeline-page.html"
              className="text-base font-semibold mb-0.5"
            >
              {' '}
              Stella Johnson
            </a>
            <p className="font-medium text-sm">843K Following </p>
            <button className="bg-gray-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
              Following
            </button>
          </div>
        </div>
        <div className="card">
          <a href="timeline-page.html">
            <img
              src="/assets/images/avatars/avatar-4.jpg"
              className="h-36 object-cover rounded-t-md shadow-sm w-full"
            />
          </a>
          <div className="p-3">
            <a
              href="timeline-page.html"
              className="text-base font-semibold mb-0.5"
            >
              {' '}
              John Michael{' '}
            </a>
            <p className="font-medium text-sm">843K Following </p>
            <button className="bg-gray-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
              Following
            </button>
          </div>
        </div>

        <div className="card">
          <a href="timeline-page.html">
            <img
              src="/assets/images/avatars/avatar-3.jpg"
              className="h-36 object-cover rounded-t-md shadow-sm w-full"
            />
          </a>
          <div className="p-3">
            <a
              href="timeline-page.html"
              className="text-base font-semibold mb-0.5"
            >
              Alex Dolgove{' '}
            </a>
            <p className="font-medium text-sm">843K Following </p>
            <button className="bg-gray-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
              Following
            </button>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-6">
        <a
          href="#"
          className="bg-white font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white"
        >
          Load more ..
        </a>
      </div>
    </div>
  );
};

export default ProfileServices;
