import React from 'react';
import CompanyLogo from './CompanyLogo';

const AllCompanies = () => {
  return (
    <div>
      <br />

      <div className="my-4 flex items-center justify-between border-b pb-3">
        <div>
          <h2 className="text-xl font-semibold"> All Companies</h2>
        </div>
      </div>

      <div className="relative" uk-slider="finite: true">
        <div className="uk-slider-container px-1 py-3">
          <ul className="uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
            <CompanyLogo />
            <CompanyLogo />
            <CompanyLogo />
            <CompanyLogo />
            <CompanyLogo />
            <CompanyLogo />
            <CompanyLogo />
            <CompanyLogo />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllCompanies;
