import React from 'react';

const ProductList = () => {
  return (
    <div>
      <br />

      <div className="my-4 flex items-center justify-between border-b pb-3">
        <div>
          <h2 className="text-xl font-semibold"> Brand Collection</h2>
        </div>
        <a href="#" className="text-blue-500">
          {' '}
          See all{' '}
        </a>
      </div>

      <div className="relative" uk-slider="finite: true">
        <div className="uk-slider-container px-1 py-3">
          <ul className="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
            <li>
              <a href="product-single.html">
                <div className="card">
                  <div className="card-media h-40">
                    <div className="card-media-overly"></div>
                    <img src="/assets/images/product/12.jpg" alt="" />
                    <span className="absolute bg-white px-2 py-1 text-sm rounded-md m-2">
                      {' '}
                      Label
                    </span>
                    <button className="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                      <i className="icon-feather-heart"> </i>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                      $22.99
                    </div>
                    <div className="text-xs font-semibold uppercase text-yellow-500">
                      Shoes
                    </div>
                    <div className="ext-lg font-medium mt-1 t truncate">
                      {' '}
                      Hermes Rivera{' '}
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="product-single.html">
                <div className="card">
                  <div className="card-media h-40">
                    <div className="card-media-overly"></div>
                    <img src="/assets/images/product/15.jpg" alt="" />
                    <button className="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                      <i className="icon-feather-heart"> </i>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                      $12.99
                    </div>
                    <div className="text-xs font-semibold uppercase text-yellow-500">
                      Herbel
                    </div>
                    <div className="ext-lg font-medium mt-1 t truncate">
                      {' '}
                      Herbal Shampoo{' '}
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="product-single.html">
                <div className="card">
                  <div className="card-media h-40">
                    <div className="card-media-overly"></div>
                    <img src="/assets/images/product/4.jpg" alt="" />
                    <button className="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                      <i className="icon-feather-heart"> </i>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                      $122.99
                    </div>
                    <div className="text-xs font-semibold uppercase text-yellow-500">
                      Mobiles
                    </div>
                    <div className="ext-lg font-medium mt-1 t truncate">
                      {' '}
                      iPhone 7 unboxing{' '}
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="product-single.html">
                <div className="card">
                  <div className="card-media h-40">
                    <div className="card-media-overly"></div>
                    <img src="/assets/images/product/14.jpg" alt="" />
                    <button className="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                      <i className="icon-feather-heart"> </i>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                      $32.99
                    </div>
                    <div className="text-xs font-semibold uppercase text-yellow-500">
                      Furniture
                    </div>
                    <div className="ext-lg font-medium mt-1 t truncate">
                      {' '}
                      Wood Chair{' '}
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="product-single.html">
                <div className="card">
                  <div className="card-media h-40">
                    <div className="card-media-overly"></div>
                    <img src="/assets/images/product/9.jpg" alt="" />
                    <button className="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                      <i className="icon-feather-heart"> </i>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                      $32.99
                    </div>
                    <div className="text-xs font-semibold uppercase text-yellow-500">
                      Fruit
                    </div>
                    <div className="ext-lg font-medium mt-1 t truncate">
                      {' '}
                      Strawberries FreshRipe{' '}
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="product-single.html">
                <div className="card">
                  <div className="card-media h-40">
                    <div className="card-media-overly"></div>
                    <img src="/assets/images/product/2.jpg" alt="" />
                    <button className="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                      <i className="icon-feather-heart"> </i>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                      $32.99
                    </div>
                    <div className="text-xs font-semibold uppercase text-yellow-500">
                      Accessories
                    </div>
                    <div className="ext-lg font-medium mt-1 t truncate">
                      Wireless headphones
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>

          <a
            className="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white"
            href="#"
            uk-slider-item="previous"
          >
            {' '}
            <i className="icon-feather-chevron-left"></i>
          </a>
          <a
            className="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white"
            href="#"
            uk-slider-item="next"
          >
            {' '}
            <i className="icon-feather-chevron-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
