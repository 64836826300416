import React from 'react';
import ProductList from '../components/ProductList';

const Products = () => {
  return (
    <div className="main_content">
      <div className="mcontainer">
        <div className="flex justify-between items-center relative md:mb-4 mb-3">
          <div className="flex-1">
            <h2 className="text-2xl font-semibold"> Products </h2>
            {/* <nav className="responsive-nav border-b md:m-0 -mx-4">
                            <ul>
                                <li className="active"><a href="#" className="lg:px-2">   Suggestions </a></li>
                                <li><a href="#" className="lg:px-2"> Newest </a></li>
                                <li><a href="#" className="lg:px-2"> My products </a></li>
                            </ul>
                        </nav> */}
          </div>
          <a
            href="#offcanvas-create"
            uk-toggle
            className="flex items-center justify-center h-8 lg:px-3 px-2 rounded-md bg-blue-600 text-white space-x-1 absolute right-0 z-10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-4"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="md:block hidden"> Create </span>
          </a>
        </div>

        <ProductList />
      </div>
    </div>
  );
};

export default Products;
