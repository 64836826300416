import React, { useEffect, useState } from 'react';
import Feed from './pages/Feed';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import CompnayProfile from './pages/CompnayProfile';
import Login from './pages/Login';
import Register from './pages/Register';
import Chats from './pages/Chats';
import Upgrade from './pages/Upgrade';
import AboutUs from './pages/AboutUs';
import Products from './pages/Products';
import Product from './pages/Product';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OpenChat from './components/OpenChat';
import StoryPreview from './components/StoryPreview';
import CreatePostModal from './components/CreatePostModal';
import endpoints from './apis/endpoints';
import Companies from './pages/Companies';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const checkAuthority = async () => {
      return await endpoints
        .profile()
        .then(() => {
          setLoading(false);
        })
        .catch(async () => {});
    };
    checkAuthority();
  }, []);
  return (
    <Router>
      <div id="wrapper">
        <Header />
        <Sidebar />

        <Routes>
          <Route path="/" exact={true} element={<Feed />} />
          <Route path="/feed" element={<Feed />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<CompnayProfile />} />
          <Route
            path="/company-profile/:username"
            element={<CompnayProfile />}
          />
          <Route path="/chats" element={<Chats />} />
          <Route path="/upgrade" element={<Upgrade />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/companies" element={<Companies />} />
        </Routes>

        {!loading && (
          <div>
            <OpenChat />
            <StoryPreview />
            <CreatePostModal />
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
