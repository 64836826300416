import React from 'react';

const StoryPreview = () => {
  return (
    <div className="story-prev">
      <div className="story-sidebar uk-animation-slide-left-medium">
        <div className="md:flex justify-between items-center py-2 hidden">
          <h3 className="text-2xl font-semibold"> All Story </h3>
          <a href="#" className="text-blue-600">
            {' '}
            Setting
          </a>
        </div>

        <div className="story-sidebar-scrollbar" data-simplebar>
          <h3 className="text-lg font-medium"> Your Story </h3>

          <a
            className="flex space-x-4 items-center hover:bg-gray-100 md:my-2 py-2 rounded-lg hover:text-gray-700"
            href="#"
          >
            <svg
              className="w-12 h-12 p-3 bg-gray-200 rounded-full text-blue-500 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              ></path>
            </svg>
            <div className="flex-1">
              <div className="text-lg font-semibold"> Create a story </div>
              <div className="text-sm -mt-0.5">
                {' '}
                Share a photo or write something.{' '}
              </div>
            </div>
          </a>

          <h3 className="text-lg font-medium lg:mt-3 mt-1"> Friends Story </h3>

          <div
            className="story-users-list"
            uk-switcher="connect: #story_slider ; toggle: > * ; animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium "
          >
            <a href="#">
              <div className="story-media">
                <img src="/assets/images/avatars/avatar-1.jpg" alt="" />
              </div>
              <div className="story-text">
                <div className="story-username"> Dennis Han</div>
                <p>
                  {' '}
                  <span className="story-count"> 2 new </span>{' '}
                  <span className="story-time"> 4Mn ago</span>{' '}
                </p>
              </div>
            </a>
            <a href="#">
              <div className="story-media">
                <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
              </div>
              <div className="story-text">
                <div className="story-username"> Adrian Mohani</div>
                <p>
                  {' '}
                  <span className="story-count"> 1 new </span>{' '}
                  <span className="story-time"> 1hr ago</span>{' '}
                </p>
              </div>
            </a>
            <a href="#">
              <div className="story-media">
                <img src="/assets/images/avatars/avatar-3.jpg" alt="" />
              </div>
              <div className="story-text">
                <div className="story-username">Alex Dolgove </div>
                <p>
                  {' '}
                  <span className="story-count"> 3 new </span>{' '}
                  <span className="story-time"> 2hr ago</span>{' '}
                </p>
              </div>
            </a>
            <a href="#">
              <div className="story-media">
                <img src="/assets/images/avatars/avatar-4.jpg" alt="" />
              </div>
              <div className="story-text">
                <div className="story-username"> Stella Johnson </div>
                <p>
                  {' '}
                  <span className="story-count"> 2 new </span>{' '}
                  <span className="story-time"> 3hr ago</span>{' '}
                </p>
              </div>
            </a>
            <a href="#">
              <div className="story-media">
                <img src="/assets/images/avatars/avatar-5.jpg" alt="" />
              </div>
              <div className="story-text">
                <div className="story-username"> Adrian Mohani </div>
                <p>
                  {' '}
                  <span className="story-count"> 1 new </span>{' '}
                  <span className="story-time"> 4hr ago</span>{' '}
                </p>
              </div>
            </a>
            <a href="#">
              <div className="story-media">
                <img src="/assets/images/avatars/avatar-8.jpg" alt="" />
              </div>
              <div className="story-text">
                <div className="story-username"> Dennis Han</div>
                <p>
                  {' '}
                  <span className="story-count"> 2 new </span>{' '}
                  <span className="story-time"> 8Hr ago</span>{' '}
                </p>
              </div>
            </a>
            <a href="#">
              <div className="story-media">
                <img src="/assets/images/avatars/avatar-6.jpg" alt="" />
              </div>
              <div className="story-text">
                <div className="story-username"> Adrian Mohani</div>
                <p>
                  {' '}
                  <span className="story-count"> 1 new </span>{' '}
                  <span className="story-time"> 12hr ago</span>{' '}
                </p>
              </div>
            </a>
            <a href="#">
              <div className="story-media">
                <img src="/assets/images/avatars/avatar-7.jpg" alt="" />
              </div>
              <div className="story-text">
                <div className="story-username">Alex Dolgove </div>
                <p>
                  {' '}
                  <span className="story-count"> 3 new </span>{' '}
                  <span className="story-time"> 22hr ago</span>{' '}
                </p>
              </div>
            </a>
            <a href="#">
              <div className="story-media">
                <img src="/assets/images/avatars/avatar-8.jpg" alt="" />
              </div>
              <div className="story-text">
                <div className="story-username"> Stella Johnson </div>
                <p>
                  {' '}
                  <span className="story-count"> 2 new </span>{' '}
                  <span className="story-time"> 3Dy ago</span>{' '}
                </p>
              </div>
            </a>
            <a href="#">
              <div className="story-media">
                <img src="/assets/images/avatars/avatar-5.jpg" alt="" />
              </div>
              <div className="story-text">
                <div className="story-username"> Adrian Mohani </div>
                <p>
                  {' '}
                  <span className="story-count"> 1 new </span>{' '}
                  <span className="story-time"> 4Dy ago</span>{' '}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="story-content">
        <ul className="uk-switcher uk-animation-scale-up" id="story_slider">
          <li className="relative">
            <span uk-switcher-item="previous" className="slider-icon is-left">
              {' '}
            </span>
            <span uk-switcher-item="next" className="slider-icon is-right">
              {' '}
            </span>

            <div uk-lightbox="true">
              <a href="/assets/images/avatars/avatar-lg-2.jpg" data-alt="Image">
                <img
                  src="/assets/images/avatars/avatar-lg-2.jpg"
                  className="story-slider-image"
                  data-alt="Image"
                />
              </a>
            </div>
          </li>
          <li className="relative">
            <span uk-switcher-item="previous" className="slider-icon is-left">
              {' '}
            </span>
            <span uk-switcher-item="next" className="slider-icon is-right">
              {' '}
            </span>

            <div uk-lightbox="true">
              <a href="/assets/images/avatars/avatar-lg-1.jpg" data-alt="Image">
                <img
                  src="/assets/images/avatars/avatar-lg-1.jpg"
                  className="story-slider-image"
                  data-alt="Image"
                />
              </a>
            </div>
          </li>
          <li className="relative">
            <span uk-switcher-item="previous" className="slider-icon is-left">
              {' '}
            </span>
            <span uk-switcher-item="next" className="slider-icon is-right">
              {' '}
            </span>

            <div uk-lightbox="true">
              <a href="/assets/images/avatars/avatar-lg-4.jpg" data-alt="Image">
                <img
                  src="/assets/images/avatars/avatar-lg-4.jpg"
                  className="story-slider-image"
                  data-alt="Image"
                />
              </a>
            </div>
          </li>

          <li className="relative">
            <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse">
              {' '}
            </div>
          </li>
          <li className="relative">
            <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse">
              {' '}
            </div>
          </li>
          <li className="relative">
            <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse">
              {' '}
            </div>
          </li>
          <li className="relative">
            <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse">
              {' '}
            </div>
          </li>
          <li className="relative">
            <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse">
              {' '}
            </div>
          </li>
          <li className="relative">
            <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse">
              {' '}
            </div>
          </li>
          <li className="relative">
            <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse">
              {' '}
            </div>
          </li>
          <li className="relative">
            <div className="bg-gray-200 story-slider-placeholder shadow-none animate-pulse">
              {' '}
            </div>
          </li>
        </ul>
      </div>

      <span
        className="story-btn-close"
        uk-toggle="target: body ; cls: story-active"
        uk-tooltip="title:Close story ; pos: left"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </span>
    </div>
  );
};

export default StoryPreview;
