import axios from 'axios';

// create base url for api using axios
const baseUrl = 'http://localhost:3333/api/v1';

export const instance = axios.create({
  baseURL: baseUrl,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

export const imgUrl = 'http://localhost:3333/uploads/';

export const imgUrlCompressed = 'http://localhost:3333/compressed/';
