import { instance } from './urls';

export default {
  login: async (data) => {
    return await instance.post('/login', data);
  },
  register: async (data) => {
    return await instance.post('/register', data);
  },
  profile: async () => {
    return await instance.get(`/profile`);
  },
};
