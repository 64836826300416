import React from 'react';
import ProfileCover from '../components/ProfileCover';
import ProfileServices from '../components/ProfileServices';
import ProfileVideos from '../components/ProfileVideos';
import Timeline from '../components/Timeline';

const CompnayProfile = () => {
  return (
    <div class="main_content">
      <div class="mcontainer">
        <ProfileCover />

        <div class="uk-switcher lg:mt-8 mt-4" id="timeline-tab">
          <Timeline />

          <ProfileServices />

          <ProfileVideos />
        </div>
      </div>
    </div>
  );
};

export default CompnayProfile;
