import React from 'react';
import CreatePost from './CreatePost';
import Post from './Post';
import PostHeader from './PostHeader';

const Timeline = () => {
  return (
    <div className="md:flex md:space-x-6 lg:mx-16 uk-active">
      <div className="space-y-5 flex-shrink-0 md:w-7/12">
        <CreatePost />

        <div className="card lg:mx-0 uk-animation-slide-bottom-small">
          <PostHeader />

          <div uk-lightbox>
            <a href="/assets/images/avatars/avatar-lg-3.jpg">
              <img
                src="/assets/images/avatars/avatar-lg-4.jpg"
                alt=""
                className="max-h-96 w-full object-cover"
              />
            </a>
          </div>

          <Post />
        </div>

        <div className="flex justify-center mt-6">
          <a
            href="#"
            className="bg-white font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white"
          >
            Load more ..
          </a>
        </div>
      </div>

      <div className="w-full space-y-6">
        <div className="widget card p-5">
          <h4 className="text-lg font-semibold"> About </h4>
          <ul className="text-gray-600 space-y-3 mt-3">
            <li className="flex items-center space-x-2">
              <ion-icon
                name="home-sharp"
                className="rounded-full bg-gray-200 text-xl p-1 mr-3"
              ></ion-icon>
              Live In <strong> Cairo , Eygept </strong>
            </li>
            <li className="flex items-center space-x-2">
              <ion-icon
                name="globe"
                className="rounded-full bg-gray-200 text-xl p-1 mr-3"
              ></ion-icon>
              From <strong> Aden , Yemen </strong>
            </li>
            <li className="flex items-center space-x-2">
              <ion-icon
                name="heart-sharp"
                className="rounded-full bg-gray-200 text-xl p-1 mr-3"
              ></ion-icon>
              From <strong> Relationship </strong>
            </li>
            <li className="flex items-center space-x-2">
              <ion-icon
                name="logo-rss"
                className="rounded-full bg-gray-200 text-xl p-1 mr-3"
              ></ion-icon>
              Flowwed By <strong> 3,240 People </strong>
            </li>
          </ul>
          <div className="gap-3 grid grid-cols-3 mt-4">
            <img
              src="/assets/images/avatars/avatar-lg-2.jpg"
              alt=""
              className="object-cover rounded-lg col-span-full"
            />
            <img
              src="/assets/images/avatars/avatar-2.jpg"
              alt=""
              className="rounded-lg"
            />
            <img
              src="/assets/images/avatars/avatar-4.jpg"
              alt=""
              className="rounded-lg"
            />
            <img
              src="/assets/images/avatars/avatar-5.jpg"
              alt=""
              className="rounded-lg"
            />
          </div>
          <a href="#" className="button gray mt-3 w-full">
            {' '}
            Edit{' '}
          </a>
        </div>

        <div className="widget card p-5 border-t">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h4 className="text-lg font-semibold"> Friends </h4>
              <p className="text-sm"> 3,4510 Friends</p>
            </div>
            <a href="#" className="text-blue-600 ">
              See all
            </a>
          </div>
          <div className="grid grid-cols-3 gap-3 text-gray-600 font-semibold">
            <a href="#">
              <div className="avatar relative rounded-md overflow-hidden w-full h-24 mb-2">
                <img
                  src="/assets/images/avatars/avatar-1.jpg"
                  alt=""
                  className="w-full h-full object-cover absolute"
                />
              </div>
              <div className="text-sm truncate"> Dennis Han </div>
            </a>
            <a href="#">
              <div className="avatar relative rounded-md overflow-hidden w-full h-24 mb-2">
                <img
                  src="/assets/images/avatars/avatar-2.jpg"
                  alt=""
                  className="w-full h-full object-cover absolute"
                />
              </div>
              <div className="text-sm truncate"> Erica Jones </div>
            </a>
            <a href="#">
              <div className="avatar relative rounded-md overflow-hidden w-full h-24 mb-2">
                <img
                  src="/assets/images/avatars/avatar-3.jpg"
                  alt=""
                  className="w-full h-full object-cover absolute"
                />
              </div>
              <div className="text-sm truncate"> Stella Johnson </div>
            </a>
            <a href="#">
              <div className="avatar relative rounded-md overflow-hidden w-full h-24 mb-2">
                <img
                  src="/assets/images/avatars/avatar-4.jpg"
                  alt=""
                  className="w-full h-full object-cover absolute"
                />
              </div>
              <div className="text-sm truncate"> Alex Dolgove</div>
            </a>
            <a href="#">
              <div className="avatar relative rounded-md overflow-hidden w-full h-24 mb-2">
                <img
                  src="/assets/images/avatars/avatar-5.jpg"
                  alt=""
                  className="w-full h-full object-cover absolute"
                />
              </div>
              <div className="text-sm truncate"> Jonathan Ali </div>
            </a>
            <a href="#">
              <div className="avatar relative rounded-md overflow-hidden w-full h-24 mb-2">
                <img
                  src="/assets/images/avatars/avatar-6.jpg"
                  alt=""
                  className="w-full h-full object-cover absolute"
                />
              </div>
              <div className="text-sm truncate"> Erica Han </div>
            </a>
          </div>
          <a href="#" className="button gray mt-3 w-full">
            {' '}
            See all{' '}
          </a>
        </div>

        <div className="widget card p-5 border-t">
          <div className="flex items-center justify-between mb-2">
            <div>
              <h4 className="text-lg font-semibold"> Groups </h4>
            </div>
            <a href="#" className="text-blue-600 ">
              {' '}
              See all
            </a>
          </div>
          <div>
            <div className="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50">
              <a
                href="timeline-group.html"
                className="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative"
              >
                <img
                  src="/assets/images/group/group-3.jpg"
                  className="absolute w-full h-full inset-0 "
                  alt=""
                />
              </a>
              <div className="flex-1">
                <a
                  href="timeline-page.html"
                  className="text-base font-semibold capitalize"
                >
                  {' '}
                  Graphic Design{' '}
                </a>
                <div className="text-sm text-gray-500 mt-0.5">
                  {' '}
                  345K Following
                </div>
              </div>
              <a
                href="timeline-page.html"
                className="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold bg-blue-500 text-white"
              >
                Join
              </a>
            </div>
            <div className="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50">
              <a
                href="timeline-group.html"
                className="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative"
              >
                <img
                  src="/assets/images/group/group-4.jpg"
                  className="absolute w-full h-full inset-0 "
                  alt=""
                />
              </a>
              <div className="flex-1">
                <a
                  href="timeline-page.html"
                  className="text-base font-semibold capitalize"
                >
                  {' '}
                  Mountain Riders{' '}
                </a>
                <div className="text-sm text-gray-500 mt-0.5">
                  {' '}
                  452k Following{' '}
                </div>
              </div>
              <a
                href="timeline-page.html"
                className="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold bg-blue-500 text-white"
              >
                Join
              </a>
            </div>
            <div className="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50">
              <a
                href="timeline-group.html"
                className="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative"
              >
                <img
                  src="/assets/images/group/group-2.jpg"
                  className="absolute w-full h-full inset-0"
                  alt=""
                />
              </a>
              <div className="flex-1">
                <a
                  href="timeline-page.html"
                  className="text-base font-semibold capitalize"
                >
                  {' '}
                  Coffee Addicts{' '}
                </a>
                <div className="text-sm text-gray-500 mt-0.5">
                  {' '}
                  845K Following
                </div>
              </div>
              <a
                href="timeline-page.html"
                className="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold bg-blue-500 text-white"
              >
                Join
              </a>
            </div>
            <div className="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50">
              <a
                href="timeline-group.html"
                className="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative"
              >
                <img
                  src="/assets/images/group/group-1.jpg"
                  className="absolute w-full h-full inset-0"
                  alt=""
                />
              </a>
              <div className="flex-1">
                <a
                  href="timeline-page.html"
                  className="text-base font-semibold capitalize"
                >
                  {' '}
                  Architecture{' '}
                </a>
                <div className="text-sm text-gray-500 mt-0.5">
                  {' '}
                  237K Following
                </div>
              </div>
              <a
                href="timeline-page.html"
                className="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold bg-blue-500 text-white"
              >
                Join
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
