import React from 'react';

const PostHeader = () => {
  return (
    <div className="flex justify-between items-center lg:p-4 p-2.5">
      <div className="flex flex-1 items-center space-x-4">
        <a href="#">
          <img
            src="/assets/images/avatars/avatar-2.jpg"
            className="bg-gray-200 border border-white rounded-full w-10 h-10"
          />
        </a>
        <div className="flex-1 font-semibold capitalize">
          <a href="#" className="text-black dark:text-gray-100">
            {' '}
            Johnson smith{' '}
          </a>
          <div className="text-gray-700 flex items-center space-x-2">
            {' '}
            5 <span> hrs </span> <ion-icon name="people"></ion-icon>
          </div>
        </div>
      </div>
      <div>
        <a href="#">
          {' '}
          <i className="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i>{' '}
        </a>
        <div
          className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
          uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small"
        >
          <ul className="space-y-1">
            <li>
              <a
                href="#"
                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
              >
                <i className="uil-share-alt mr-1"></i> Share
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
              >
                <i className="uil-edit-alt mr-1"></i> Edit Post
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
              >
                <i className="uil-comment-slash mr-1"></i> Disable comments
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
              >
                <i className="uil-favorite mr-1"></i> Add favorites
              </a>
            </li>
            <li>
              <hr className="-mx-2 my-2 dark:border-gray-800" />
            </li>
            <li>
              <a
                href="#"
                className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
              >
                <i className="uil-trash-alt mr-1"></i> Delete
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PostHeader;
