import React, { useState, useEffect } from 'react';
import endpoints from '../apis/endpoints';

export const APIContext = React.createContext();

const Context = ({ children }) => {
  return <APIContext.Provider value={{}}>{children}</APIContext.Provider>;
};

export default Context;
