import React from 'react';
import PostHeader from './PostHeader';

const Post = () => {
  return (
    <div className="card lg:mx-0 uk-animation-slide-bottom-small">
      <PostHeader />

      <div uk-lightbox="true">
        <a href="/assets/images/avatars/avatar-lg-3.jpg">
          <img
            src="/assets/images/avatars/avatar-lg-4.jpg"
            alt=""
            className="max-h-96 w-full object-cover"
          />
        </a>
      </div>
      <div className="p-4 space-y-3">
        <div className="flex space-x-4 lg:font-bold">
          <a href="#" className="flex items-center space-x-2">
            <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                width="22"
                height="22"
                className="dark:text-gray-100"
              >
                <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
              </svg>
            </div>
            <div> Like</div>
          </a>
          <a href="#" className="flex items-center space-x-2">
            <div className="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                width="22"
                height="22"
                className="dark:text-gray-100"
              >
                <path
                  fillRule="evenodd"
                  d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div> Comment</div>
          </a>
        </div>
        <div className="flex items-center space-x-3 pt-2">
          <div className="flex items-center">
            <img
              src="/assets/images/avatars/avatar-1.jpg"
              alt=""
              className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900"
            />
            <img
              src="/assets/images/avatars/avatar-4.jpg"
              alt=""
              className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900 -ml-2"
            />
            <img
              src="/assets/images/avatars/avatar-2.jpg"
              alt=""
              className="w-6 h-6 rounded-full border-2 border-white dark:border-gray-900 -ml-2"
            />
          </div>
          <div className="dark:text-gray-100">
            Liked <strong> Johnson</strong> and <strong> 209 Others </strong>
          </div>
        </div>

        <div className="border-t py-4 space-y-4 dark:border-gray-600">
          <div className="flex">
            <div className="w-10 h-10 rounded-full relative flex-shrink-0">
              <img
                src="/assets/images/avatars/avatar-1.jpg"
                alt=""
                className="absolute h-full rounded-full w-full"
              />
            </div>
            <div>
              <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12  dark:bg-gray-800 dark:text-gray-100">
                <p className="leading-6">
                  In ut odio libero vulputate{' '}
                  <span className="i uil-heart"></span>{' '}
                  <i className="uil-grin-tongue-wink"> </i>{' '}
                </p>
                <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
              </div>
              <div className="text-sm flex items-center space-x-3 mt-2 ml-5">
                <a href="#" className="text-red-600">
                  {' '}
                  <i className="uil-heart"></i> Love{' '}
                </a>
                <a href="#"> Replay </a>
                <span> 3d </span>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-10 h-10 rounded-full relative flex-shrink-0">
              <img
                src="/assets/images/avatars/avatar-1.jpg"
                alt=""
                className="absolute h-full rounded-full w-full"
              />
            </div>
            <div>
              <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12  dark:bg-gray-800 dark:text-gray-100">
                <p className="leading-6">
                  {' '}
                  sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                  magna aliquam erat volutpat. David !
                  <i className="uil-grin-tongue-wink-alt"></i>{' '}
                </p>
                <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
              </div>
              <div className="text-xs flex items-center space-x-3 mt-2 ml-5">
                <a href="#" className="text-red-600">
                  {' '}
                  <i className="uil-heart"></i> Love{' '}
                </a>
                <a href="#"> Replay </a>
                <span> 3d </span>
              </div>
            </div>
          </div>
        </div>

        <a href="#" className="hover:text-blue-600 hover:underline">
          {' '}
          Veiw 8 more Comments{' '}
        </a>

        <div className="bg-gray-100 rounded-full relative dark:bg-gray-800 border-t">
          <input
            placeholder="Add your Comment.."
            onChange={() => {}}
            className="bg-transparent max-h-10 shadow-none px-5"
          />
          <div className="-m-0.5 absolute bottom-0 flex items-center right-3 text-xl">
            <a href="#">
              <ion-icon
                name="happy-outline"
                className="hover:bg-gray-200 p-1.5 rounded-full"
              ></ion-icon>
            </a>
            <a href="#">
              <ion-icon
                name="image-outline"
                className="hover:bg-gray-200 p-1.5 rounded-full"
              ></ion-icon>
            </a>
            <a href="#">
              <ion-icon
                name="link-outline"
                className="hover:bg-gray-200 p-1.5 rounded-full"
              ></ion-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
