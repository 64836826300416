import React from 'react';

const ProfileVideos = () => {
  return (
    <div className="card md:p-6 p-2 max-w-3xl mx-auto">
      <h2 className="text-xl font-semibold"> Videos</h2>
      <nav className="responsive-nav border-b">
        <ul>
          <li className="active">
            <a href="#" className="lg:px-2">
              {' '}
              Suggestions{' '}
            </a>
          </li>
          <li>
            <a href="#" className="lg:px-2">
              {' '}
              Newest{' '}
            </a>
          </li>
          <li>
            <a href="#" className="lg:px-2">
              {' '}
              My Videos{' '}
            </a>
          </li>
        </ul>
      </nav>

      <div className="grid md:grid-cols-3 grid-cols-2  gap-x-2 gap-y-4 mt-3">
        <div>
          <a
            href="video-watch.html"
            className="w-full h-36 overflow-hidden rounded-lg relative block"
          >
            <img
              src="/assets/images/video/img-1.png"
              alt=""
              className="w-full h-full absolute inset-0 object-cover"
            />
            <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs">
              {' '}
              12:21
            </span>
            <img
              src="/assets/images/icon-play.svg"
              className="w-12 h-12 uk-position-center"
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="video-watch.html"
            className="w-full h-36 overflow-hidden rounded-lg relative block"
          >
            <img
              src="/assets/images/video/img-2.png"
              alt=""
              className="w-full h-full absolute inset-0 object-cover"
            />
            <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs">
              {' '}
              12:21
            </span>
            <img
              src="/assets/images/icon-play.svg"
              className="w-12 h-12 uk-position-center"
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="video-watch.html"
            className="w-full h-36 overflow-hidden rounded-lg relative block"
          >
            <img
              src="/assets/images/video/img-3.png"
              alt=""
              className="w-full h-full absolute inset-0 object-cover"
            />
            <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs">
              {' '}
              12:21
            </span>
            <img
              src="/assets/images/icon-play.svg"
              className="w-12 h-12 uk-position-center"
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="video-watch.html"
            className="w-full h-36 overflow-hidden rounded-lg relative block"
          >
            <img
              src="/assets/images/video/img-4.png"
              alt=""
              className="w-full h-full absolute inset-0 object-cover"
            />
            <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs">
              {' '}
              12:21
            </span>
            <img
              src="/assets/images/icon-play.svg"
              className="w-12 h-12 uk-position-center"
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="video-watch.html"
            className="w-full h-36 overflow-hidden rounded-lg relative block"
          >
            <img
              src="/assets/images/video/img-5.png"
              alt=""
              className="w-full h-full absolute inset-0 object-cover"
            />
            <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs">
              {' '}
              12:21
            </span>
            <img
              src="/assets/images/icon-play.svg"
              className="w-12 h-12 uk-position-center"
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="video-watch.html"
            className="w-full h-36 overflow-hidden rounded-lg relative block"
          >
            <img
              src="/assets/images/video/img-6.png"
              alt=""
              className="w-full h-full absolute inset-0 object-cover"
            />
            <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs">
              {' '}
              12:21
            </span>
            <img
              src="/assets/images/icon-play.svg"
              className="w-12 h-12 uk-position-center"
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="video-watch.html"
            className="w-full h-36 overflow-hidden rounded-lg relative block"
          >
            <img
              src="/assets/images/video/img-3.png"
              alt=""
              className="w-full h-full absolute inset-0 object-cover"
            />
            <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs">
              {' '}
              12:21
            </span>
            <img
              src="/assets/images/icon-play.svg"
              className="w-12 h-12 uk-position-center"
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="video-watch.html"
            className="w-full h-36 overflow-hidden rounded-lg relative block"
          >
            <img
              src="/assets/images/video/img-2.png"
              alt=""
              className="w-full h-full absolute inset-0 object-cover"
            />
            <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs">
              {' '}
              12:21
            </span>
            <img
              src="/assets/images/icon-play.svg"
              className="w-12 h-12 uk-position-center"
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="video-watch.html"
            className="w-full h-36 overflow-hidden rounded-lg relative block"
          >
            <img
              src="/assets/images/video/img-4.png"
              alt=""
              className="w-full h-full absolute inset-0 object-cover"
            />
            <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs">
              {' '}
              12:21
            </span>
            <img
              src="/assets/images/icon-play.svg"
              className="w-12 h-12 uk-position-center"
              alt=""
            />
          </a>
        </div>
      </div>

      <div className="flex justify-center mt-6">
        <a
          href="#"
          className="bg-white font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white"
        >
          Load more ..
        </a>
      </div>
    </div>
  );
};

export default ProfileVideos;
