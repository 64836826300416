import React from 'react';
import OpenChat from '../components/OpenChat';

const AboutUs = () => {
  return (
    <div className="is-collapse">
      <div className="main_content">
        <div
          className="w-full lg:h-80 h-52 pb-10 bg-cover flex justify-center items-center relative"
          data-src="/assets/images/group/group-cover-1.jpg"
          uk-img
        >
          <div className="text-center max-w-xl mx-auto z-10 relative">
            <div className="lg:text-4xl text-2xl text text-white font-semibold mb-3">
              {' '}
              About Socialite
            </div>
            <div className="text-white text-lg font-medium text-opacity-90">
              {' '}
              Socialite Is a template for developers who want to start their
              next social web application UI
            </div>
          </div>

          <div className="absolute w-full h-full inset-0 bg-gray-600 bg-opacity-20">
            {' '}
          </div>
        </div>
        <div className="mcontainer">
          <div className="-mt-16 bg-white max-w-3xl mx-auto p-10 relative rounded-md shadow">
            <div className="md:space-y-6 space-y-5 text-gray-400 md:text-base">
              <div className="font-semibold md:text-2xl text-lg text-gray-700">
                Write about your website here
              </div>
              <div className="md:leading-8">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat.
              </div>

              <div className="font-semibold md:text-xl text-lg text-gray-700">
                {' '}
                Remote is not for everyone
              </div>
              <div className="md:leading-8">
                Laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                minim veniam, quis nostrud exerci tation ullamcorper suscipit
                lobortis nisl ut aliquip ex ea commodo consequat.
              </div>
              <div className="md:leading-8">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat. Nam liber tempor cum soluta
                nobis eleifend option congue nihil imperdiet doming id quod
                mazim placerat facer possim assum.
              </div>
              <div className="md:leading-8">
                Aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
